@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";


html {
  scroll-behavior: smooth;
  background-color: white;
}

a, p, h1, h2, h3, h4, strong, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

@layer components {

  .button {
    @apply inline-flex px-6 md:px-10 py-4 border font-medium no-underline uppercase antialiased transition-colors duration-500 leading-none tracking-wide;
  }

  .front-h1 {
    @apply text-2xl lg:text-3xl tracking-wide font-light uppercase text-black/80;
  }

  .front-h2 {
    @apply block-h2 mt-0;
  }

  .front-h3 {
    @apply block-h3;
  }

  .page-h1 {
    @apply text-3xl font-light text-gray-700;
  }

  .page-h2 {
    @apply text-xl md:text-3xl font-semibold my-4 text-primary;
  }

  .block-h2 {
    @apply text-2xl lg:text-3xl font-light text-gray-700 mb-8 after:bg-primary after:h-0.5 after:block after:w-10 after:mt-6;
  }

  .block-h3 {
    @apply text-2xl font-semibold py-4 mb-2 text-secondary after:block after:bg-accent after:h-0.5 after:w-full after:mt-2 antialiased ;
  }

  .block-btn-primary { /** block buttons **/
    @apply button text-white border-2 group-hover:border-primary bg-primary/50 backdrop-blur-lg tracking-wider text-center;
  }

  .page-btn-primary { /** front page CTA **/
    @apply button bg-primary text-white border-transparent hover:border-primary hover:bg-white;
  }

  .page-btn-secondary  { 
    @apply button text-primary border-gray-200 backdrop-blur-lg hover:border-primary hover:bg-white hover:text-primary tracking-wider;
  }

}

/** WP image alignments **/


.alignleft, .alignright, .aligncenter {
  margin-top: 0 !important;
}


@media screen and (min-width: 768px){
  .alignleft {
    float: left;
    max-width: 50%;
    margin-right: 2.5rem;
  }

  .alignright {
    float: right;
    max-width: 50%;
    margin-left: 2.5rem;
  }

  .aligncenter {
    margin: 1.5rem !important;
  }
}
