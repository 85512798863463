/* outfit-300 - light */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/outfit/outfit-v5-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/outfit/outfit-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/outfit/outfit-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/outfit/outfit-v5-latin-300.svg#Outfit') format('svg'); /* Legacy iOS */
}

/* outfit-regular */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/outfit/outfit-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/outfit/outfit-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/outfit/outfit-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/outfit/outfit-v5-latin-regular.svg#Outfit') format('svg'); /* Legacy iOS */
}

/* outfit-500 - medium */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/outfit/outfit-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/outfit/outfit-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/outfit/outfit-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/outfit/outfit-v5-latin-500.svg#Outfit') format('svg'); /* Legacy iOS */
}
/* outfit-600 - latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/outfit/outfit-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/outfit/outfit-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/outfit/outfit-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/outfit/outfit-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/outfit/outfit-v5-latin-600.svg#Outfit') format('svg'); /* Legacy iOS */
}